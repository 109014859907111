import { onUpdate } from "../../../function/onUpdate";
import { getInvoice, updateInvoice } from "./query";

export default async function onUpdateInvoice({ values, client }) {
  const fieldsToUpdate = [
    { field: 'date', createUpdate: false },
    { field: 'number', createUpdate: false },
    { field: 'itemNumber', createUpdate: false },
    { field: 'batch', createUpdate: false },
    { field: 'qty', createUpdate: true },
    { field: 'price', createUpdate: true },
    { field: 'category', createUpdate: true },
    { field: 'paymentDueDate', createUpdate: true },
    { field: 'deductionDate', createUpdate: false },
    { field: 'paymentStatus', createUpdate: false },
    { field: 'forwarderPOID', createUpdate: false },
    { field: 'statusCode', createUpdate: false },

    // { field: "qty", createUpdate: true },
    // { field: "openQty", createUpdate: true },
  ]

  return await onUpdate({
    type: "Invoice",
    values,
    mutation: updateInvoice,
    query: getInvoice,
    getInitialValue: null,
    fieldsToUpdate,
    client,
    refetchQueries: [],
  });
}
